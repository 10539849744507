<template>
  <div class="charts">
        <div v-if="visible" v-once>
            <img :src="'/img/chartAnimals/'+ index +'.png'" @click="imgSearch">
            <div class="anim">
                <lottie :options="defaultOptions"  :width="220" v-on:animCreated="handleAnimation" :key="index" />
            </div>
        </div>
  </div>
</template>

<script>
import Lottie from './lottie.vue';
import animation from './animations/dataChart.json';
import barramento from '@/barramento'

export default {
    components: {
      'lottie': Lottie
    },
    props: [ 'item', 'index', 'i' ],
    data() {
        return {
            visible: true,
            defaultOptions: Object,
            animController: Object,
            jsonObject: [animation],
            array: {'MAMIFEROS': 1262, 'TUBARAO_RAIA': 1856, 'AVES': 1258, 'REPTEIS': 1266, 'ANFIBIOS': 1257, 'PEIXES_CONTINENTAIS': 1264, 'PEIXES_MARINHOS': 1265, 'INVERTEBRADOS_TERRESTRES': 1261, 'INVERTEBRADOS_AGUA_DOCE': 1259, 'INVERTEBRADOS_MARINHOS': 1260}
        }
    },
    watch: {
        defaultOptions(){
            this.visible = true
        }
    },
    created() {

        let lottie = JSON.parse(JSON.stringify(this.jsonObject))
        lottie[0].layers[1].t.d.k[0].s.t = this.item.label

        let crTotal = 0
        let crPerc = 0

        if (this.item.categories.CR != undefined) {
            crTotal = this.item.categories.CR.total
            crPerc =  this.item.categories.CR.percent
        }

        let enTotal = 0
        let enPerc = 0
        if (this.item.categories.EN != undefined) {
            enTotal = this.item.categories.EN.total
            enPerc = this.item.categories.EN.percent
        }

        let vuTotal = 0
        let vuPerc = 0
        if (this.item.categories.VU != undefined) {
            vuTotal = this.item.categories.VU.total
            vuPerc = this.item.categories.VU.percent
        }

        var total = crTotal + enTotal + vuTotal

        lottie[0].layers[0].t.d.k[0].s.t = total.toString() //total
        lottie[0].layers[1].t.d.k[0].s.t = this.item.label //nome
        lottie[0].layers[6].ef[0].ef[0].v.k = vuPerc //val amarelo
        lottie[0].layers[5].ks.s.k[2].s[1] = vuPerc //size amarelo

        lottie[0].layers[8].ef[0].ef[0].v.k = enPerc //val laranja
        lottie[0].layers[7].ks.s.k[2].s[1] = enPerc //size laranja

        lottie[0].layers[4].ef[0].ef[0].v.k = crPerc //val vermelho
        lottie[0].layers[3].ks.s.k[2].s[1] = crPerc //size

        this.defaultOptions = {animationData: lottie[0], loop: false}

    },
    methods: {
        handleAnimation(anim) {
            anim.addEventListener("complete", function() {
                this.addClickEventsHandler();
            }.bind(this))
            this.anim = anim;
            setTimeout(() => {
                anim.playSegments([0, 25], true)

            }, 4000);
        },
        addClickEventsHandler() {
            var groupId = this.array[this.index]
            var groupName = this.item.label

            var item = document.getElementsByClassName("chartRed")[this.i]
            var endpoint = "?grupoIds=" + groupId + "&categoriaIds=130"
            item.style.cursor = "pointer"
            item.addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': endpoint, 'items': {'categoriaIds': [130], 'grupoIds': groupId}, 'filter': groupName + ' Criticamente em Perigo (CR)'})
            });

            var item2 = document.getElementsByClassName("chartYellow")[this.i]
            var endpoint2 = "?grupoIds=" + groupId + "&categoriaIds=132"
            item2.style.cursor = "pointer"
            item2.addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': endpoint2, 'items': {'categoriaIds': [132], 'grupoIds': groupId}, 'filter': groupName + ' Vulnerável (VU)'})
            });

            var item3 = document.getElementsByClassName("chartOrange")[this.i]
            var endpoint3 = "?grupoIds=" + groupId + "&categoriaIds=131"
            item3.style.cursor = "pointer"
            item3.addEventListener("click", function() {
                barramento.$emit('openModalR', {'query': endpoint3, 'items': {'categoriaIds': [131], 'grupoIds': groupId}, 'filter': groupName + ' Em Perigo (EN)'})
            });
        },
        imgSearch(){
            var groupName = this.item.label
            var groupId = this.array[this.index]
            var endpoint = "?grupoIds=" + groupId + "&threatened=s"
            barramento.$emit('openModalR', {'query': endpoint, 'items': {'grupoIds': groupId}, 'filter': groupName})
        }
    }
}
</script>

<style scoped>
    .charts {
        margin-top: -20px;
    }
    .anim {
        margin-top: -255px;
        z-index: 10;
    }
    img {
        margin-top: 12px;
        position: relative;
        z-index: 10000;
        cursor: pointer;
    }
</style>
